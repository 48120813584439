// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/icons/CouponIcon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/icons/CouponIcon.tsx");
  import.meta.hot.lastModified = "1731508025725.8018";
}
// REMIX HMR END

export function CouponIcon({
  lineClassName,
  glyphClassName
}) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
      <path d="M5.7 22.2V20c0-1.6 1.3-3 2.9-3h22.8c1.6 0 2.9 1.4 2.9 3v2c0 .5-.3.8-.7.9-1 .2-2.2 1-2.2 2.8 0 1.7 1.2 2.5 2.2 2.7.4.1.7.4.7.8v2.1c0 1.6-1.3 3-3 3H8.7a2.9 2.9 0 0 1-2.9-3v-2c0-.5.3-.8.7-.9 2.2-.4 2.2-2.7 2.2-2.7 0-1.8-1.1-2.6-2.2-2.8-.4-.1-.7-.4-.7-.8Z" strokeWidth="1.8" strokeLinejoin="round" className={lineClassName} />
      <path d="M14.3 17.2v7.1M14.3 27.2v1.4M14.3 31.5v2.8" strokeWidth="1.8" strokeLinecap="round" className={lineClassName} />
      <path d="M31.4 17.2 15.8 4.7c-1.3-1-3.1-.8-4.1.5l-1.3 1.6c-.2.3-.2.8 0 1.1.8.8 1.1 2.1 0 3.5 0 0-1.4 1.9-3.3.9-.4-.2-.8-.2-1 .2L4.6 14c-1 1.3-.8 3 .5 4l.5.5" strokeWidth="1.8" strokeLinejoin="round" className={lineClassName} />
      <path d="m17.1 11.5 2.2-2.9M12.9 17.2l2-2.9" strokeWidth="1.8" strokeLinecap="round" className={lineClassName} />
      <path d="M20.5 21.2a.5.5 0 0 0 .3 1l-.3-1Zm5.2 1.8.4-.2-.4.2Zm-4.8 4v-.5.5Zm-2.3-.6h.5-.5Zm3-1.8v-.5.5Zm-.8-2.4c.6-.2 1.7-.3 2.6-.2 1 .1 1.6.5 1.8 1.1l1-.3c-.4-1.2-1.6-1.7-2.7-1.8-1-.1-2.2 0-3 .2l.3 1Zm4.4 1c0 .2 0 .5-.2.9-.2.3-.5.7-1 1-1 .7-2.2 1.2-3.2 1.4l.2 1c1.1-.3 2.5-.8 3.6-1.6.5-.4 1-.8 1.3-1.3.3-.5.4-1.1.2-1.8l-1 .3Zm-4.4 3.3-1.2.2-.4-.1-.1-.3-1 .2c0 .4.3.7.6 1l.9.2 1.4-.2-.2-1Zm-1.7-.2V26s0-.2.2-.3c.3-.2 1-.5 2.4-.6l-.1-1c-1.4.1-2.3.4-2.9.8-.3.3-.5.5-.6.8v.8l1-.2Zm2.6-1.2c.8 0 1.8.1 2.9.5l.3-1a7.8 7.8 0 0 0-3.3-.5v1Z" className={glyphClassName} />
      <path d="M26.4 25.8c2.4 2-1.6 4-3.5 4.2" strokeLinecap="round" strokeLinejoin="round" className={lineClassName} />
    </svg>;
}
_c = CouponIcon;
var _c;
$RefreshReg$(_c, "CouponIcon");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;